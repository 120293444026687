@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.product {
  &__text {
    width: 100%;
    margin-top: $between-components;
    border-radius: 4px;
    padding: $between-components;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
  }

  &__name {
    width: 100%;
    color: white;
    @include heading;
    @include fonts(1.8rem);
    padding-top: $between-components;
    line-height: normal;

    @include tablet {
    }

    @include desktop {
      font-size: 2.8rem;
      line-height: 4rem;
      padding-bottom: $between-components;
      min-height: 50px;
    }
  }

  &__blob {
    position: absolute;
    width: 60%;
    top: -14.5%;
    right: -2.7rem;

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: $edge-padding;
      width: 70%;
      min-height: 328px;
      position: absolute;
      background-color: $dark-brown;
      border-radius: 4px;
      top: 3.7rem;
      right: 3.5rem;

      @include desktop {
        min-height: 580px;
        padding: $edge-padding $section-padding $section-padding;
        top: 6rem;
      }
    }

    &__description {
      display: flex;
      width: 100%;
      color: white;
      font-weight: $medium;
      padding-bottom: $mobile-side-padding;

      @include desktop {
        @include fonts(2rem);
        padding-bottom: $between-components;
      }
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
  }

  &__inventory {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    padding: 0.5rem;
    color: white;
    line-height: 1rem;
    background-color: black;
    border-radius: 4px 0 0 4px;
    outline: 1px solid $dark-brown;

    &--text {
      display: flex;
      justify-content: center;
      font-size: 0.65rem;
      width: 100%;
      margin-top: 0.1rem;

      @include mobile-large {
        font-size: 0.8rem;
      }

      @include tablet {
        font-size: 0.65rem;
      }

      @include desktop {
        font-size: 1rem;
        margin-top: 0.5rem;
      }
    }

    &--counter {
      font-size: 1rem;
      display: flex;
      justify-content: center;
      font-weight: 600;

      @include desktop {
        font-size: 1.5rem;
      }
    }
  }

  &__btn {
    color: black;
    text-transform: uppercase;
    border: 0;
    border-radius: 0 4px 4px 0;
    font-size: 1.3rem;
    font-weight: 600;
    padding: $mobile-menu-padding;
    background-color: white;
    width: 75%;
    z-index: 10;

    &--hover {
      @extend .product__btn;
      cursor: pointer;
      transition: background-color 0.2s;
      transition: color 0.1s;
    }

    @include desktop {
      font-size: 2rem;
      padding: $edge-padding;
    }
  }
}

.mobile-hidden {
  display: none;

  @include tablet {
    display: block;
  }
}

.tab-hidden {
  @include tablet {
    display: none;
  }
}

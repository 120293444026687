@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.nav {
    position: relative;

    &__responsive {
        padding-bottom: $between-components;
        background-color: $dark-brown;
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        position: absolute;
        z-index: 20;
    }

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__item {
        padding: $mobile-menu-padding 0;
        color: white;
        font-size: $nav;

    }

    &__hidden {
        display: none;
    }
}
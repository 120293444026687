@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

#disclaimer{
    text-align: left;
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;

}

#title{
    width: 90%;
    text-align: left;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#titleContent{
    width: 100%;
    
}

#sendEmail{
    position: relative;
    justify-content: center;
    width: 80%;
    margin-left: 6vw;
    margin-top: 4vw;
    @include desktop{
        margin-left: 2vw;
        margin-top: 4vw;
    }
}

#innerbox{
    width: 90%;
}

.checkout {
    &__content {
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 5rem $edge-padding;

        @include tablet {
            flex-direction: row;
        }
        @include desktop {
            padding-top: 10rem;
        }
    }

    &__review {
        width: 95%;
        background-color: $medium-brown;
        border-radius: 10px;
        margin-bottom: $edge-padding;
        display: flex;
        padding: 0.5rem;
        margin-top: 10rem;
        max-width: 300px;
        min-height: 450px;

        @include tablet {
            margin: 0 $edge-padding;
            max-width: 400px;
        }
        @include desktop {
            margin-top: 3rem;
        }
    }

    &__inner-box {
        background-color: white;
        color: $dark-brown;
        width: 80%;
        border-radius: 4px;
        align-items: center;

    }
    &__title {
        margin: 0px auto;
        display: flex;
        width: 100%;
        height:20%; 
        
    }

    &__disclaimer {
        display: flex;
        justify-content: center;
        align-items: center;
        height:60%; 
        &--text {
            font-size: 0.8rem;
            color: black;
            opacity: 0.6;
        }
    }

    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.5;
        z-index: -5;
    }
}


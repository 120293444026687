@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

#headerTitle{
  text-align: center;
}

.navbar {
  position: sticky;
  top: 11vh;
  background-color: #333;
  padding: 10px;
  z-index: 50;
}

.navbar-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Added property for centering */

}

.navbar-item {
  cursor: pointer;
  color: #fff;
  padding: 10px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.navbar-item:hover {
  background-color: #555;
}

.navbar-item.active {
  background-color: #777;
}
.section {
  padding-top: 180px; /* Adjust this value as needed */
}

#adminPage{
  padding-top: 25vw;
  @include desktop {
    padding-top: 10vw;  
  }
}

#cancelButton{
  color: red;
  border: 1px solid red;
  height: 1.5vw;
  justify-content: center;
  width: 1.5vw;
}

#search{
  display: flex;
  justify-content: center;
  margin: auto;
  padding-bottom: 2vw;
}

#container{
  margin: auto;
  padding-left: 11vw;
}

#filter{
  display: flex;
  justify-content: center;
  width: 10%;
  margin: auto;
  padding-bottom: 1vw;
  margin-right: 5vw;
  @include desktop{
    margin-right: 0vw;
  }
}

#reviewFilter{
  display: flex;
  justify-content: center;
  margin: auto;
  padding-bottom: 3vw;

}

#show{
  display: flex;
  margin: auto;
  align-content: center;
}


#profits{
  display: flex;
  margin: auto;
  padding-left: 2vw;
}

#change{
  margin-left: 1vw;
  margin-right: 1vw;
}

#checkBox{
  width: 10w;
  height: 10vw;
  margin-left:0.5vw;
  @include desktop{
    margin-right: 0vw;
    width: 3w;
    height: 3vw;
  }
}

#filterPage{
  width:20%;
  margin: auto;
  margin-left: 1vw;
}

#filterBar{
  display: flex;
  position: relative;
  padding-left: 8vw;
  flex-direction: row;
  margin: auto;
  width: 80%;
  margin-bottom: 2vw;
  justify-content: space-between;
  @include desktop{
    padding-left: 0vw;
  }

}

#beforeDate{
  width: 20%;
  margin: auto;
  margin-right: 30vw;
  margin-left: 10vw;
  @include desktop{
    margin-right: 0vw;
    margin-left: 2vw;
  }
}
#afterDate{
  width: 17%;
  margin: auto;
  margin-right: 30vw;
  @include desktop{
    margin-right: 0vw;
  }
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
}

table td,
#customers th {
  border: 1px solid black;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: $medium-brown;
  color: black;
  border: 1px solid black;
}


.genTable {
  
  justify-content: center;
  width: 90%;
  padding-bottom: 3vw;
  padding-top: 2vw;
  
  flex-direction: column;
  align-items: center;
  display: fixed;
  margin:auto;

  @include desktop{
    display: flex;
  }
}

.transaction {
  background-color: $medium-brown;
  width: 80%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  margin-left: 3rem;
  &__add {
    display: flex;
    width: 50%;
    flex-direction: column;
    border-right: 1px solid black;
    padding: 1rem;
  }

  &__name{
    &--label{
      padding-top: 2rem;
    }
  }

  &__amount{
    &--label {
      padding-top: 2rem;
    }
  }

  &__preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    width: 50%;
    background-color: $light-brown;
  }
}

.order {
  flex-direction: column;
  align-items: center;
  width: 90%;
  display: fixed;
  margin:auto;
}

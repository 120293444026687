@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

#minusButton{
    border:1px solid black;
    height:100%;
    text-align: center;
    width: 30%;
    padding: 0;
    max-width: 35%;
    min-width: 35%;
}
#buyButton{
    background-color: $dark-brown;
    border: 1px solid $dark-brown;
}

#plusButton{
    border:1px solid black;
    text-align: center;
    height: 100%;
    width: 30%;
    padding: 0;
    max-width: 35%;
    min-width: 35%;
}

#quantity{
    border:1px solid black;
    width: 100%;
    text-align: center;
    min-width: 50%;
}

#quantity_title{
    width: 48%;
    text-align: right;
}

#details_title{
    width: 27%;
}

#total_title{
    width: 25%;
    text-align: right;
}

.checkout {
    &__overview {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $edge-padding;
        min-height: 550px;
    }

    &__sub-title {
        font-size: 0.8rem;
        text-transform: uppercase;
        color: $dark-brown;
        border-bottom: 1px solid $medium-brown;
        display: flex;
        justify-content: space-between;
    }
    &__buttons{
        display: flex;
        width: 30%;
        height: 40%;
        justify-content: center;
        padding-left: 3vw;
        padding-right: 3vw;
        @include desktop{
            padding-left: 1vw;
            padding-right: 1vw;
        }
    }

    &__list {
        min-height: 210px;
        border-bottom: 1px solid $medium-brown;
        margin-bottom: 1rem;
    }
    &__item {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        width: 100%;
        padding-top: 1vw;

        @include desktop{
            padding-bottom: 2vw;
            padding-top: 0vw;

        }

    }
    &__item_name {
        display: flex;
        justify-content: left;
        font-size: 1rem;
        width: 40%;
    }
    &__item_total {
        display: flex;
        justify-content: right;
        font-size: 1rem;
        padding-left: 1vw;
        width: 40%;
    }

    &__subtotal {
        display: flex;
        justify-content: space-between;
    }

    &__button {
        width: 100%;
        background-color: $medium-brown;
        border: 2px solid $medium-brown;
        border-radius: 4px;
        padding: 1rem;
        color: white;
        margin-top: 1.9rem;
        @include fonts(1.4rem);
        text-align: center;

        &--hover {
            @extend .checkout__button;
            transition: background-color 0.2s;
            color: 0.2s;
            background-color: $dark-brown;
            border: 2px solid $dark-brown;
            color: white;
        }
    }
}

.hidden {
    display: none;
}
@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.about {
  min-height: 1250px;
  position: relative;

  @include tablet {
    min-height: 1025px;
  }

  @include desktop {
    
    min-height: 1150px;
    display: flex;
    justify-content: center;
  }

  &__background {
    background-color: $medium-brown;
    position: absolute;
    z-index: -2;
    min-height: 700px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  &__container {
    position: absolute;
    width: 100%;
    padding: $edge-padding;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
        flex-direction: row;
        flex-wrap: wrap;
        padding: $section;
        align-items: flex-start;
        justify-content: center;
        max-width: 1280px;
        margin: 0 auto;
    }

    @include desktop {
        max-width: 1280px;
        padding: 10rem 0;
        margin: 0 auto;
        justify-content: space-between;
    }
  }

  &__image {
    width: 100%;
    object-fit: contain;
    border: 1rem solid white;
    border-radius: 4px;
    max-width: 400px;

    @include tablet {
        width: 50%;
        min-height: 775px;
        border: 1.618rem solid white;
    }

    @include desktop {
        max-width: none;
        border: 2rem solid rgba(159,137,110, 0.8)
    }

    &--vivian {
        border-radius: 4px;

        @include tablet {
            min-height: 775px;
            object-fit: cover;
            opacity: 1;
            
        }
    }
  }

  &__text-containers {

    @include tablet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
        margin-left: 2rem;
        min-height: 687px;
    }
    @include desktop {
        width: 45%;
        min-height: 850px;
    }
  }

  &__definition {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 4px;
    width: 100%;
    background-color: white;
    max-width: 400px;

    @include tablet {
        width: 100%;
        padding: 2rem;
    }

    @include desktop {
        max-width: none;
        padding: 2.5rem;
    }

    &--name {
        font-size: 1.5rem;
        opacity: 0.8;
        letter-spacing: 1px;

        @include desktop {
            font-size: 2rem;
        }

        &--pro {
            font-size: 1rem;
            font-style: italic;
            letter-spacing: 0;
            @include desktop {
                font-size: 1.2rem;
            }
        }

    }

    &--subtitle {
        border-bottom: 1px solid black;
        padding-bottom: 1rem;
        margin-bottom: 4px;
        font-size: 0.8rem;
        letter-spacing: 1px;
        font-weight: 300;
        @include desktop {
            font-size: 1rem;
        }
    }

    &--detail {
        font-size: 0.8rem;
        font-weight: 300;

        @include desktop {
            font-size: 1rem;
        }

        &--bold {
            font-weight: $demi;
        }
    }
  }

  &__description {
    background-color: white;
    color: rgb(29, 29, 29);
    border-radius: 4px;
    min-height: 200px;
    max-width: 400px;
    padding: $edge-padding 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;


    @include tablet {
        padding: 2rem 1.618rem;
    }

    @include desktop {
        max-width: none;
        padding: 3rem;
    }

    &--header {
        color: rgba(159,137,110, 0.8);
        font-weight: $demi;
        font-size: $title;
        padding-bottom: 2rem;
        @include heading;
        letter-spacing: 2px;

        @include desktop {
            font-size: 3rem;
        }

    }

    &--text {
        padding-bottom: 1rem;
        line-height: 1.8rem;

        @include desktop {
            font-size: 1.3rem;
            line-height: 2.6rem;
        }
    }

    &--signoff {
        font-size: 1rem;
        display: flex;
        justify-content: flex-end;
        margin-right: 0.5rem;

        @include desktop {
            font-size: 1.3rem;
            margin-right: 0.8rem;
        }
    }
    
    
  }
}
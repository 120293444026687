@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;



#follow{
    color: $dark-brown;
    text-align: center;
}

.review {
    width: 100%;
    padding: 5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include tablet {
        padding: 5rem 2rem;
    }

    @include desktop {
        max-width: 1280px;
        margin: 0 auto;
        padding: 5rem 0;
    }

    &__container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding: 5%;
        
        @include tablet {
        flex-direction: row;
        padding: 0;
        justify-content: space-between;
        }
    }

    &__card {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        align-items: center;
        color: black;
        border-radius: 4px;
        background-color: white;
        border: 0.5rem solid $medium-brown;
        margin-bottom: 1rem;
        min-height: 200px;
        max-width: 350px;

        @include tablet {
            max-width: 300px;
            margin: 3rem 1rem;
        }

        @include desktop {
            max-width: 400px;
        }
    }

    &__content {
        min-height: 180px;
        width: 100%;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid $medium-brown;
    }

    &__btn {
        width: 89%;
        color: white;
        background-color: black;
        border: none;
        padding: 1.2rem 1rem;
        border-radius: 4px;
        font-size: 1.4rem;
        max-width: 350px;
    }
}



.review-card {

}

.review-btn {
    background-color: var(--orange-light);
    padding: 2% 3%;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 5%;
}

@media screen and (min-width: 1024px) {
    .reviews-container {
        flex-direction: row;
    }
}
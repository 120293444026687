@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.checkout {

    &__form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $edge-padding;
        min-height: 550px;
    }

    &__title {
        display: flex;
        align-items:center;
        min-height: 70px;
    }

    &__sub-header{
        font-size: 0.8rem;
        text-transform: uppercase;
        color: $dark-brown;
        border-bottom: 1px solid $medium-brown;
    }

    &__field {
        padding-top: 10vw;
        @include desktop{
            padding-top: 0;
        }
        @include tablet{
            padding-top: 2vw;
        }
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;
        border-bottom: 1px solid $medium-brown;
    }

    &__label {
        font-size: 11px;
        text-transform: uppercase;
        opacity: 0.8;
        padding-top: 0.7rem;
        margin-bottom: -4px;

    }

    &__input {
        border: 1px solid $medium-brown;
        border-radius: 4px;
        padding: 2px 8px;
        font-size: 0.9rem;

        &:focus {
            outline: 1px solid $dark-brown;
            background-color: rgba(248, 244, 237, 0.5)
        }
        &::placeholder {
            color: $medium-brown;
        }
    }

    &__disclaimer {
        padding-top: 1rem;

        &--text {
            font-size: 0.8rem;
            color: black;
            opacity: 0.6;
        }
    }
}

.checkout__contact {
    margin-bottom: -4rem;
}
@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

#blobId{
    padding-top: 10vw;
}

.hero {
    position: relative;

    &__image {
        padding-top: 5rem;
        display: flex;
        align-items: center;
        width: 100%;

        @include desktop {
            padding-top: 1rem;
            justify-content: center;
            max-height: 100vh;
            margin-top: -13vh;
        }

        &--photo {
            z-index: -1;
            width: 100%;
            opacity: 0.9;

        }
    }

    &__blob {
        position: absolute;
        top: 4rem;
        right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;


        @include desktop {
            width: 40%;
            right: 9.5rem;
            top: 2rem;
        }

        &--el {
            opacity: 0.9;
            width: 100%;
        }
    }

    &__text {
        width: 100%;
        height: 80%;
        max-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction:  column;
        position: absolute;
        text-align: center;

        &--header {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 50%;
            width: 70%;
            max-width: 700px;
            font-weight: $demi;
            text-align: center;
            color: $dark-brown;
            font-size: 1em;

            @include mobile-large {
                width: 85%;
                font-size: 3.8vw;
                margin-bottom: 4vw;
            }

            @include desktop {
                font-size: 3vw;
                line-height: 4rem;
            }

        }

        &--brand {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 50%;
            width: 100%;
            max-width: 700px;
            text-transform: uppercase;
            @include heading;
            font-size: 1.2em;
            color: white;

            @include mobile-large {
                font-size: 7vw;
            }

            @include desktop {
                font-size: 6vw;
            }
        }
    }

}
.mobile-hidden {
    display: none;

    @include tablet {
        display: block;
    }
}
@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.carousel {

    &__item {
        width: 100%;
        display: flex;
        justify-content: center;
        border-radius: 4px;

        @include tablet {    
            width: 50%;
            max-height: 350px;
            object-fit: contain;
        }

        @include desktop {
            max-height: none;
        }
    }
}

.swiper {
    @include tablet {
        width: 100%;
    }
}
.swiper-container {
    margin: none;
}

.slider-controller {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 80px;
}

.swiper-button-prev {
    position: static;
    z-index: 3;
    color: $dark-brown;

}

.swiper-button-next {
    @extend .swiper-button-prev;
}

.swiper-pagination {
    width: 80%;
    display: flex;
    justify-content: center;
}

.swiper-pagination-bullet-active {
    background-color: $dark-brown;
}
@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.loader__bg {
    padding-top: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader__image {
    width: 30%;
    animation: pulse 1.5s infinite ease-in-out;

    @include tablet {
      width: 10%;
    }
}


@keyframes pulse {
    from {
      transform: scale(0.9);
      transform: rotate(0);
    }
    to {
      transform: scale(1);
      transform: rotate(360deg)
    }
  }
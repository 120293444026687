@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.checkout {
    position: relative;

    &__content {
        top: 0;
        display: flex;
        flex-direction: column;
        position: absolute;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0rem $edge-padding;

        @include tablet {
            flex-direction: row;
        }

        @include desktop {
            padding-top: 9rem;
        }
    }

    &__review {
        width: 95%;
        background-color: $medium-brown;
        border-radius: 4px;
        margin-bottom: $edge-padding;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        max-width: 300px;
        min-height: 450px;

        @include tablet {
            margin: 0 $edge-padding;
            max-width: 400px;
        }
    }

    &__summary {
        @extend .checkout__review;

        @include tablet {
            min-height: 550px;
        }
    }

    &__inner-box {
        background-color: white;
        color: $dark-brown;
        width: 100%;
        min-height: calc(440px);
        border-radius: 4px;
        
        @include tablet {
            min-height: calc(550px);
        }
    }


    &__background {
        position: absolute;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        opacity: 0.5;
        z-index: -5;
    }
}
@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

#navBar {
    top: 0;
    position:fixed;
    width: 100%;
    z-index: 100;
}

#count{
    z-index: 1;
    position: absolute;
    top:13vw;
    @include desktop {
        top: 1.5vw;
    }
    @include tablet {
        bottom: 50vw;
    }
}


.nav {
    background: $dark-brown; 

    @include tablet {
        background: linear-gradient(130deg, $dark-brown 25%, #af977a 45%);
    }

    &__container {
        display: flex;
        padding: 1rem $edge-padding;
        justify-content: space-between;
        width: 100%;

        @include tablet {
            padding: 0;
            justify-content: space-between;
        }

        @include desktop {
            max-width: 1200px;
            margin: 0 auto;
        }
    }

    &__logo {
        width: 20%;
        max-width: 75px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }

    &__title {
        width: 80%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include tablet {
            width: 30%;
            align-items: center;
            margin-top: 0;
            max-width: 200px;
            padding: $edge-padding $large-edge-padding;

            @include desktop {
                padding-left: 0;

            }
        }

        &--text {
            color: white;
            @include fonts($subheader);
            @include heading;
            padding-top: 0.3rem;
            padding-left: 0.4rem;

            @include tablet {
                padding-top: 0;
            }
        }
    }

    &__icon {
        width: 1.5rem;
        max-width: 30px;

        @include tablet {
            width: 15%;
        }
    }

    &__burger {
        width: 20%;
        max-width: 75px;
        margin-top: 0.3rem;
        color: white;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__menu {
        font-size: $mobile-title;
        line-height: 1.6rem;

    }

    //stylings for tablet and larger nav list items
    &__large__nav {
        display: none;

        @include tablet {
            margin-right: 5%;
            display: flex;
            width: 50%;
            justify-content: flex-end;
            max-width: 400px;
        }

        @include desktop {
            width: 80%;
            margin-right: 0;
            max-width: 800px
        }
    }

    &__large__list {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include desktop {
            width: 55%;
        }

    }

    &__large__item {
        color: rgba($color: white, $alpha: 1);
        @include fonts($nav);
        height: 100%;
        padding: 1rem 0 0;
        @include desktop {
            padding: 2rem 0 0;
        }
    }

    &__count {
        color: rgba($color: white, $alpha: 1);
        @include fonts($nav);
        font-size: 1rem;
        position: sticky;
        height: 100%;
        padding: 0rem 1.5rem 0;
        @include desktop {
            font-size: 1rem;
            padding-left: 2rem;
        }
        z-index: 1;
    }

    &__cart {
        font-size: 1.5rem;
        position: sticky;
        bottom: 7vw;

        @include desktop {
            margin-top: 0;
            font-size: 2rem;
        }

        @include tablet {
            left: 90vw;
            bottom: 4vw;
        }
    }
}

.tab-hidden {
    @include tablet {
        display: none;
    }
}
.active {
    border-bottom: 5px solid $light-brown;
}
@mixin tablet {
    @media(min-width: 768px) {
        @content;
    }
}

@mixin mobile-large {
    @media(min-width: 425px){
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
}

@mixin fonts($size, $weight: 400) {
    font-size: $size;
    line-height: ($size * 1.618);
    font-weight: $weight;
}
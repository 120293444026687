@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

#connect{
    @include desktop{
        font-size: 1.7vw;
    }
}



.email-update {
    width: 100%;
    height: 500px;
    background-color: $medium-brown;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.email-update > div {
    margin-top: 2%;
}
.email-container {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.email-update input {
    width: 70%;
    padding: 1% 0 1% 1%;
    background-color: $light-brown;
    border: none;
    margin: auto;
}

.email-update input:focus {
    outline: solid 2px $dark-brown;
    border-radius: 2px;
}

.button-container {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.emailButton {
    width: 100%;
    color: white;
    background-color:rgb(12, 12, 12);
    border: black;
    padding: 0.7rem 0.7rem;
    font-size: 1rem;
    text-align: center;
}

.email-container button {
    margin-bottom: 2%;
    background-color: $medium-brown;
    width: 10%;
    height: 10%;
    color: rgb(88, 88, 88);
    text-transform: uppercase;
    font-size: 0.8em;
    border: none;
}

.instagram-plug-container {
    width: 100%;
    height: 40vw;
    @include desktop{
        height: 15vw;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon-container{
    margin-top: 2vw;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.instagram-plug-container > p {
    border-top: solid 3px black;
    padding-top: 1%;
}
.instagram-follow-btn {
    color: black;
    background-color: white;
    border: solid 3px black;
    width: 10vw;
    height: 10vw;
    @include desktop {
        width: 5.5vw;
        height: 5.5vw;
    }
    margin-top: 2%;
    padding: 1vw;
    text-transform: uppercase;
    &__image{
        object-fit: contain;
    }
}
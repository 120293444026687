
//colors 
$primary-orange: #c67f43;
$dark-primary: #893F04;
$bg-green: #dde6d5;
$dark-green: #667b68;
$dark-green-clear: rgba(102, 123, 104, 0.7);


$dark-brown: #9F896E;
$medium-brown:  #D3C6B5;
$light-brown: #F6F2EF;

$grape-purple: #806FAF;
$light-purple: #C1BBDD;

$lychee-pink: #F6A5AB;
$light-pink: #F3CEC3;

$strawberry-pink: #E6B2AF;
$strawberry-light-pink: #FDEBEB;

//fonts

$light: 300;
$medium: 500;
$demi: 600;

//MOBILE SIZING
$edge-padding: 1.5rem; //24px
$section-padding: 3rem;
$between-components: 1rem; //16px

$subheader: 1.618rem; //25.88px;
$section: 2.617rem; //41.88px;
$header: 4.236rem; //67.77px
$nav: 1.2rem;


//TABLET OR LARGER SIZING - base is 18px
$large-edge-padding: 2rem; //36px



//sizes - mobile is 16px

$mobile-title: 2rem; //32px

$title: 2.3rem;

// .heading-large {font-size: 6rem;}
// .heading-medium {font-size: 4rem;}


//paddings - mobile base size is 16px
$mobile-side-padding: 24px;
$mobile-vertical-padding: 1rem; //16px;
$mobile-menu-padding: 0.7rem;


//tablet base size is 18px
$side-padding: 2.67rem; //48px
$before-section: 4rem;

$top-padding: 5rem;
@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;


#text{
    width: 60%;
    font-size: 50%;
    @include desktop{
        width: 11%;
        font-size: 50%;
    }
    text-align: center;
    color: black;
    line-height:2vw;
}


#footer {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
@use "../../styles/partials/variables.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

#productsId{
    padding-top: 20vw;
    @include desktop {
        padding-top: $section-padding;
    }
}

.products {
    background-color: rgb(242, 242, 242);
   padding: $section-padding $edge-padding;
   position: relative;

   @include tablet {
    padding: $before-section $side-padding 10rem;
   }


   &__title {
    color: $dark-brown;
    @include fonts($section, 500);

    @include tablet {
        display: flex;
        justify-content: center;
    }
    @include desktop {
        font-size: 4rem;
        padding-bottom: $edge-padding;
        padding-top: $section-padding;
    }
   }

   &__subtitle {
    padding-bottom: $section-padding;
    @include fonts($subheader, 300);
    color: black;
    opacity: 0.7;

    @include tablet {
        padding-bottom: 6rem;
        display: flex;
        justify-content: center;
    }

    @include desktop {
        font-size: 2rem;
        padding-bottom: 8rem;
    }
   }

   &__carousel {
    @include tablet {
        width: 100%;
        max-width: 850px;
        margin: 0 auto;
    }
    @include desktop {
        max-width: 1280px;
        margin: 0 auto;
    }
   }
}
